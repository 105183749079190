<!--  -->
<template>
  <div style="width:100%;height:100%;">
      <iframe style="width:100%;height:100%;" frameborder="0" src="/frameFlow/view/app/snaAnalyze/"></iframe>
  </div>
</template>
<script>
export default {
  data() {
    return {
      
    }
  },
  //生命周期 - 创建完成（访问当前this实例）
  created() {},
  //生命周期 - 挂载完成（访问DOM元素）,函数调用
  mounted() {},
  //计算属性
  computed: {},
  //函数
  methods: {},
  //监控
  watch: {}
}
</script>
<style scoped>
/* 滚动槽 */
::-webkit-scrollbar {
    width: 6px;
    height: 6px;
}
::-webkit-scrollbar-track {
    border-radius: 3px;
    background: rgba(0,0,0,0.06);
    -webkit-box-shadow: inset 0 0 5px rgba(0,0,0,0.08);
}
/* 滚动条滑块 */
::-webkit-scrollbar-thumb {
    border-radius: 3px;
    background: rgba(0,0,0,0.12);
    -webkit-box-shadow: inset 0 0 10px rgba(0,0,0,0.2);
}
</style>
